<template>
  <form-create
    ref="form"
    v-model="dataForm"
    :label-width="'100px'"
    :components="components"
    @update="$emit('change', dataForm)"
  >
    <template slot="after">
      <slot name="after">
        <div style="text-align: center">
          <el-button type="primary" @click="handleCommand('save')"
            >确 定</el-button
          >
        </div>
      </slot>
    </template>
  </form-create>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    course_id() {
      return {};
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      dataForm: this.value,
      courseInfo: {},
    };
  },
  computed: {
    components() {
      var _this = this;
      return [
        {
          name: "form-item",
          props: {
            prop: "user_id",
            label: "选择用户",
            type: "select",
            reserveKeyword: false,
            multiple: true,
            disabled: _this.dataForm.id > 0,
            data: {
              url: "/xapi/user/list",
              labelOptions: { value: "id", label: "showname" },
            },
          },
        },
        {
          name: "form-item",
          props: {
            prop: "group_id",
            label: "选择团队",
            type: "select",
            multiple: true,
            data: {
              url: "/xapi/user.group/list",
              params: { type: 1 },
              labelOptions: { value: "id", label: "name" },
            },
          },
          hidden: _this.dataForm.id > 0,
        },
        {
          name: "form-item",
          props: {
            prop: "file",
            label: "导入文件",
            type: "upload",
            limit: 1,
            "list-type": "text",
            "show-file-list": true,
            accept: ".xlsx",
            disabledUpload: true,
            notice: '<a href="https://www.baidu.com">导入模板</a>',
          },
          slots: {
            tip: [
              {
                name: "span",
                domProps: { innerHTML: "点击下载" },
              },
              {
                name: "el-link",
                on: {
                  click: () => {
                    _this.$tool.fileResponse(
                      "",
                      { file: "import/white_user.xlsx" },
                      "白名单学生.xlsx"
                    );
                  },
                },
                domProps: { innerHTML: "<span  style='color:var(--color-danger);'>白名单导入模板</span> " },
              },
              {
                name: "span",
                domProps: {
                  innerHTML:
                    "，根据导入信息获取白名单学生<p style='color:var(--color-danger);'>注意：导入的学生信息必须已经注册<br/> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;用户、团队、导入文件必选其一</p>",
                },
              },
            ],
          },
          hidden: _this.dataForm.id > 0,
        },
        // {name:'form-item',props:{prop:'type',required:true,defaultValue:0,label:'模式',type:'radio'},callback(c){
        //     if( _this.courseInfo.learn_mode === 2){
        //       c.props.data = [ { value: 0,  label: '默认' }, { value: 2, label: '强制模式' } ];
        //     }else{
        //       c.props.data = [ { value: 0,  label: '默认' }, { value: 1, label: '引导模式' }, { value: 2, label: '强制模式' } ];
        //     }
        //   }},
      ];
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(v) {
        if (this.$tool.obj_equal(v, this.dataForm) === false) {
          this.dataForm = v;
        }
      },
    },
    course_id() {
      this.getDetail();
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    handleCommandSave() {
      this.$refs.form.validate((formData, valid) => {
        if (valid) {
          var _formData = new FormData(),
            data = { ...formData, ...this.$route.params };
          for (const dataKey in data) {
            if (data[dataKey] !== null && data[dataKey] !== undefined)
              _formData.append(dataKey, data[dataKey]);
          }
          this.$http
            .post("/xapi/course.course/studentSave", _formData)
            .then((data) => {
              this.$handleMessage(data.msg || "操作成功！", "success", () => {
                this.$emit("success");
              });
            });
        }
      });
    },
    getDetail() {
      this.courseInfo = {};
      if (this.course_id > 0) {
        this.loading = true;
        this.$http
          .post("/xapi/course.course/detail", { id: this.course_id })
          .then(({ data }) => {
            this.courseInfo = data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    // change(){
    //   console.log("555555555555555");
    // },
  },
};
</script>

<style scoped>
</style>