var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center"}},[_c('breadcrumb'),(_vm.courseInfo.is_free != 0 && _vm.isOpenWhite)?_c('el-button',{attrs:{"type":"primary","size":"small","disabled":!_vm.$isPowers('courseStudyCreate')},on:{"click":function($event){return _vm.$refs.dialog.visibled(true)}}},[_vm._v(" 新增白名单 ")]):_vm._e()],1),_c('el-card',[_c('com-table',{ref:"table",attrs:{"height":"750px","columns":[
        { prop: 'headimg', label: '头像', width:'80',slot: 'avatar',align: 'center' },
        { prop: 'showname', label: '学生姓名',align: 'center' },
        { prop: 'school_name', label: '学校名称',align: 'center' },
        { prop: 'course_name', label: '报名课程', align: 'center' },
        { prop: 'create_time', label: '报名时间', align: 'center',hidden: !_vm.isTeach  },
        // {prop:'typeStr',label:'模式',width:'100'},
        { prop: 'price', label: '付费金额', align: 'center',hidden: !_vm.isTeach },
        {
          // fixed:'right',
          label: '操作',
          slot: 'action',
          width: '500',
          align: 'center',
          // hidden: isTeach === false,
        },
      ],"data":{
        url: '/xapi/course.course/studentList',
      },"page-size":50},scopedSlots:_vm._u([{key:"action",fn:function({ row }){return [_c('el-button-group',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[(row.id==_vm.userInfo.id)?_c('el-button',{attrs:{"type":"primary","disabled":!_vm.$isPowers('courseDrop'),"size":"mini"},on:{"click":function($event){_vm.$refs.txDialog.visibled((_vm.currentData = { ...row }) && true)}}},[_vm._v("退学")]):_vm._e(),(row.typeStr !== null)?[(_vm.isTeach)?_c('el-button',{attrs:{"disabled":!_vm.$isPowers('courseStudyDel'),"type":"primary","size":"mini"},on:{"click":function($event){_vm.$handleConfirm(
                  '您确定将学生「' + row.nickname + '」踢出白名单吗?',
                  '/xapi/course.course/studentDel',
                  { ..._vm.$route.params, user_id: row.id },
                  () => {
                    _vm.$refs.table.reload();
                  },
                  { title: '踢出白名单' }
                )}}},[_vm._v("踢出白名单")]):_vm._e()]:_vm._e(),(_vm.isTeach)?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.$handleRoute(
                { study_user_id: row.id,course_id:_vm.course_id },
                'courseCourseStudySpeed'
              )}}},[_vm._v("查看学习进度")]):_vm._e(),(_vm.isTeach && _vm.courseInfo.learn_mode + '' === '2')?_c('el-button',{attrs:{"type":"primary","disabled":!_vm.$isPowers('courseStudyZYue'),"size":"mini"},on:{"click":function($event){_vm.$refs.ZYueDialog.visibled((_vm.currentData = { ...row }) && true)}}},[_vm._v(" 学习内容授权 ")]):_vm._e()],2)]}}])})],1),_c('diy-dialog',{ref:"ZYueDialog",attrs:{"title":'学习内容授权',"width":"650px","center":""}},[_c('form-create',{ref:"txForm",staticStyle:{"text-align":"left"},attrs:{"label-width":"120px","components":[
        {
          name: 'form-item',
          props: {
            prop: 'unit_list_ids',
            label: '选择章节/条目',
            multiple: true,
            type: 'data',
            showKey: 'name',
            required: true,
            // disabled:true,
            columnKey: 'key',

            dialog: {
              width: '650px',
              url: '/xapi/course.course/unitList',
              params: { course_id: _vm.course_id },
              table: {
                treeProps: {
                  hasChildren: 'hasChildren',
                  children: 'children',
                  isCheck: false,
                  // dataMethod: (children) => {
                  //   console.log(children,'55555555555');
                  //   return children
                  // },
                },
              },
            },

            whereColumns: [
              {
                name: 'form-item',
                props: {
                  prop: 'keyword',
                  label: '关键字',
                  placeholder: '请输入章节、条目名称',
                  style: { width: '350px' },
                },
              },
            ],
            columns: [
              // {
              //   prop: 'key',
              //   label: '编号',
              //   width: '120',
              //   align: 'center',
              //   fixed: 'left',
              // },
              { prop: 'title', label: '章节/条目名称', minWidth: '220' },
              {
                prop: 'create_date',
                label: '创建时间',
                width: '160',
                align: 'left',
              },
            ],
          },
        },
        {
          name: 'form-item',
          props: {
            prop: 'reason',
            label: '备注',
            // required: true,
            type: 'textarea',
          },
        },
      ]}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.$refs.ZYueDialog.visibled(false)}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleCommand('zyueSave', _vm.currentData)}}},[_vm._v("确 定")])],1)],1),(_vm.isTeach)?_c('diy-dialog',{ref:"dialog",attrs:{"title":'新增白名单',"width":"450px","center":""}},[_c('student-save',{ref:"studentSave",attrs:{"course_id":_vm.course_id},on:{"success":function($event){_vm.$refs.table.reload();
        _vm.$refs.dialog.visibled(false);}}},[_c('span',{attrs:{"slot":"after"},slot:"after"})]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.$refs.dialog.visibled(false)}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$refs.studentSave.handleCommand('save')}}},[_vm._v("确 定")])],1)],1):_vm._e(),_c('diy-dialog',{ref:"txDialog",attrs:{"title":'退学',"width":"650px","center":""}},[_c('form-create',{ref:"txForm",staticStyle:{"text-align":"left"},attrs:{"label-width":"100px","components":[
        {
          name: 'form-item',
          props: {
            prop: 'leave_type',
            label: '退学方式',
            required: true,
            type: 'radio',
            defaultValue: 1,
            data: [
                    { label: '退学不退款', value: 1 },
                  ],
            // data: {
            //   url: '/xapi/course.course/studentOptions/type/leave_type',
            // },
            // dataMethod(data) {
            //   data = data.map((d) => {
            //     d.disabled =
            //       (!Number(currentData.price) > 0 ||
            //         currentData.price == null) &&
            //       ['2', '3'].indexOf(d.value) > -1;
            //     return d;
            //   });
            // },
          },
        },
        {
          name: 'form-item',
          props: {
            prop: 'refund_price',
            label: '退款金额',
            required: true,
            disabled: false,
            value: 0.01,
            type: 'number',
            max: parseFloat(_vm.currentData.price),
          },
          condition: '{{leave_type}}==2',
          hidden: _vm.isTeach === false,
          slots: { after: '元' },
        },
        {
          name: 'form-item',
          props: {
            prop: 'refund_price',
            label: '退款金额',
            disabled: true,
            value: _vm.currentData.price,
            type: 'number',
            max: parseFloat(_vm.currentData.price),
          },
          condition: '{{leave_type}}==3',
          hidden: _vm.isTeach === false,
          slots: { after: '元' },
        },
        {
          name: 'form-item',
          props: {
            prop: 'leave_reason',
            label: '退学理由',
            required: true,
            type: 'textarea',
          },
        },
        {
          name: 'form-item',
          props: {
            prop: 'contact_mobile',
            label: '联系方式',
            required: true,
          },
          hidden: _vm.isTeach === true,
        },
      ]}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.$refs.txDialog.visibled(false)}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleCommand('txSave')}}},[_vm._v("确 定")])],1)],1),_c('diy-dialog',{ref:"zkDialog",attrs:{"title":'转课',"width":"650px","center":""}},[_c('form-create',{ref:"zkForm",staticStyle:{"text-align":"left"},attrs:{"label-width":"100px","components":[
        {
          name: 'form-item',
          props: {
            prop: 'transfer_course_id',
            label: '选择课程',
            required: true,
            type: 'select',
            data: {
              url: '/xapi/course.course/myList',
              labelOptions: { value: 'id', label: 'course_name' },
              params: { course_id: _vm.course_id, is_plan: 0, is_up: 1 },
            },
          },
        },
        {
          name: 'form-item',
          props: {
            prop: 'transfer_reason',
            label: '转课理由',
            required: true,
            type: 'textarea',
          },
        },
        {
          name: 'form-item',
          props: {
            prop: 'contact_mobile',
            label: '联系方式',
            required: true,
          },
          hidden: _vm.isTeach === true,
        },
      ]}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.$refs.zkDialog.visibled(false)}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleCommand('zkSave')}}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }