<template>
  <div>
    <div style="display: flex;justify-content: space-between;align-items: center;">
      <breadcrumb></breadcrumb>
      <el-button
      v-if="courseInfo.is_free != 0 && isOpenWhite"
        type="primary"
        size="small"
        :disabled="!$isPowers('courseStudyCreate')"
        @click="$refs.dialog.visibled(true)"
      >
        新增白名单
      </el-button>
    </div>
    <!-- <el-card
      style="margin-bottom: 20px; text-align: right"
      :body-style="'display: flex;align-items: center;padding:22px 20px 0;'"
    >
      <el-button
        v-if="courseInfo.is_free != 0 && isOpenWhite"
        type="primary"
        size="medium"
        :disabled="!$isPowers('courseStudyCreate')"
        @click="$refs.dialog.visibled(true)"
        style="margin-bottom: 22px"
      >
        新增白名单
      </el-button>
      <form-create
        style="flex: 1"
        v-model="where"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'student_name',
              label: '学生姓名',
              style: { width: '350px' },
            },
          },
        ]"
        inline
      >
        <el-form-item class="operate" slot="after">
          <el-button
            type="primary"
            @click="$refs.table.reset(where)"
            size="medium"
            >搜索</el-button
          >
          <el-button
            type="primary"
            @click="$refs.table.reset((where = {}))"
            size="medium"
            >重置</el-button
          >
        </el-form-item>
      </form-create>
      <div
        v-if="isStudent && !courseInfo.isWhite"
        style="margin-bottom: 22px; text-align: right"
      >
        <!== <el-button
          :disabled="!$isPowers('courseDrop')"
          @click="
            $refs.txDialog.visibled(
              (currentData = {
                id: userInfo.id,
                price: courseInfo.price,
                ...((($refs.table || {}).parseData || []).filter(
                  (d) => d.id === userInfo.id
                )[0] || {}),
              }) && true
            )
          "
          type="primary"
          size="medium"
        >
          退学
        </el-button> ==>
        <!== <el-button :disabled="!$isPowers('courseTransfer')" @click="$refs.zkDialog.visibled((currentData = {id:userInfo.id}) && true)" type="primary" size="medium"> 转课 </el-button> ==>
      </div>
    </el-card> -->
    
    <el-card>
      <com-table
        ref="table"
        height="750px"
        :columns="[
          { prop: 'headimg', label: '头像', width:'80',slot: 'avatar',align: 'center' },
          { prop: 'showname', label: '学生姓名',align: 'center' },
          { prop: 'school_name', label: '学校名称',align: 'center' },
          { prop: 'course_name', label: '报名课程', align: 'center' },
          { prop: 'create_time', label: '报名时间', align: 'center',hidden: !isTeach  },
          // {prop:'typeStr',label:'模式',width:'100'},
          { prop: 'price', label: '付费金额', align: 'center',hidden: !isTeach },
          {
            // fixed:'right',
            label: '操作',
            slot: 'action',
            width: '500',
            align: 'center',
            // hidden: isTeach === false,
          },
        ]"
        :data="{
          url: '/xapi/course.course/studentList',
        }"
        :page-size="50"
      >
        <template slot="action" slot-scope="{ row }">
          <el-button-group style="display: flex;align-items: center;justify-content: center;">
            <el-button
              type="primary"
              v-if="row.id==userInfo.id"
              :disabled="!$isPowers('courseDrop')"
              @click="
                $refs.txDialog.visibled((currentData = { ...row }) && true)
              "
              size="mini"
              >退学</el-button
            >
            <!-- <el-button
              type="primary"
              :disabled="!$isPowers('courseTransfer')"
              @click="
                $refs.zkDialog.visibled((currentData = { ...row }) && true)
              "
              size="mini"
              >转课</el-button
            > -->

            <template v-if="row.typeStr !== null">
              <el-button
              v-if="isTeach"
                :disabled="!$isPowers('courseStudyDel')"
                @click="
                  $handleConfirm(
                    '您确定将学生「' + row.nickname + '」踢出白名单吗?',
                    '/xapi/course.course/studentDel',
                    { ...$route.params, user_id: row.id },
                    () => {
                      $refs.table.reload();
                    },
                    { title: '踢出白名单' }
                  )
                "
                type="primary"
                size="mini"
                >踢出白名单</el-button
              >
            </template>
            <el-button
              type="primary"
              size="mini"
              v-if="isTeach"
              @click="
                $handleRoute(
                  { study_user_id: row.id,course_id:course_id },
                  'courseCourseStudySpeed'
                )
              "
              >查看学习进度</el-button
            >
            <el-button
              v-if="isTeach && courseInfo.learn_mode + '' === '2'"
              type="primary"
              :disabled="!$isPowers('courseStudyZYue')"
              @click="
                $refs.ZYueDialog.visibled((currentData = { ...row }) && true)
              "
              size="mini"
            >
              <!-- 章节/条目直阅 -->
              学习内容授权
            </el-button>
          </el-button-group>
        </template>
      </com-table>
    </el-card>
    <diy-dialog ref="ZYueDialog" :title="'学习内容授权'" width="650px" center>
      <form-create
        ref="txForm"
        style="text-align: left"
        label-width="120px"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'unit_list_ids',
              label: '选择章节/条目',
              multiple: true,
              type: 'data',
              showKey: 'name',
              required: true,
              // disabled:true,
              columnKey: 'key',

              dialog: {
                width: '650px',
                url: '/xapi/course.course/unitList',
                params: { course_id },
                table: {
                  treeProps: {
                    hasChildren: 'hasChildren',
                    children: 'children',
                    isCheck: false,
                    // dataMethod: (children) => {
                    //   console.log(children,'55555555555');
                    //   return children
                    // },
                  },
                },
              },

              whereColumns: [
                {
                  name: 'form-item',
                  props: {
                    prop: 'keyword',
                    label: '关键字',
                    placeholder: '请输入章节、条目名称',
                    style: { width: '350px' },
                  },
                },
              ],
              columns: [
                // {
                //   prop: 'key',
                //   label: '编号',
                //   width: '120',
                //   align: 'center',
                //   fixed: 'left',
                // },
                { prop: 'title', label: '章节/条目名称', minWidth: '220' },
                {
                  prop: 'create_date',
                  label: '创建时间',
                  width: '160',
                  align: 'left',
                },
              ],
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'reason',
              label: '备注',
              // required: true,
              type: 'textarea',
            },
          },
        ]"
      >
      </form-create>
      <div slot="footer" style="display: flex; justify-content: space-between">
        <el-button @click="$refs.ZYueDialog.visibled(false)">取 消</el-button>
        <el-button
          type="primary"
          @click="handleCommand('zyueSave', currentData)"
          >确 定</el-button
        >
      </div>
    </diy-dialog>
    <diy-dialog
      ref="dialog"
      :title="'新增白名单'"
      width="450px"
      center
      v-if="isTeach"
    >
      <student-save
        ref="studentSave"
        :course_id="course_id"
        @success="
          $refs.table.reload();
          $refs.dialog.visibled(false);
        "
      >
        <span slot="after"> </span>
      </student-save>
      <div slot="footer" style="display: flex; justify-content: space-between">
        <el-button @click="$refs.dialog.visibled(false)">取 消</el-button>
        <el-button
          type="primary"
          @click="$refs.studentSave.handleCommand('save')"
          >确 定</el-button
        >
      </div>
    </diy-dialog>
    <diy-dialog ref="txDialog" :title="'退学'" width="650px" center>
      <form-create
        ref="txForm"
        style="text-align: left"
        label-width="100px"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'leave_type',
              label: '退学方式',
              required: true,
              type: 'radio',
              defaultValue: 1,
              data: [
                      { label: '退学不退款', value: 1 },
                    ],
              // data: {
              //   url: '/xapi/course.course/studentOptions/type/leave_type',
              // },
              // dataMethod(data) {
              //   data = data.map((d) => {
              //     d.disabled =
              //       (!Number(currentData.price) > 0 ||
              //         currentData.price == null) &&
              //       ['2', '3'].indexOf(d.value) > -1;
              //     return d;
              //   });
              // },
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'refund_price',
              label: '退款金额',
              required: true,
              disabled: false,
              value: 0.01,
              type: 'number',
              max: parseFloat(currentData.price),
            },
            condition: '{{leave_type}}==2',
            hidden: isTeach === false,
            slots: { after: '元' },
          },
          {
            name: 'form-item',
            props: {
              prop: 'refund_price',
              label: '退款金额',
              disabled: true,
              value: currentData.price,
              type: 'number',
              max: parseFloat(currentData.price),
            },
            condition: '{{leave_type}}==3',
            hidden: isTeach === false,
            slots: { after: '元' },
          },
          {
            name: 'form-item',
            props: {
              prop: 'leave_reason',
              label: '退学理由',
              required: true,
              type: 'textarea',
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'contact_mobile',
              label: '联系方式',
              required: true,
            },
            hidden: isTeach === true,
          },
        ]"
      >
      </form-create>
      <div slot="footer" style="display: flex; justify-content: space-between">
        <el-button @click="$refs.txDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="handleCommand('txSave')"
          >确 定</el-button
        >
      </div>
    </diy-dialog>
    <diy-dialog ref="zkDialog" :title="'转课'" width="650px" center>
      <form-create
        ref="zkForm"
        style="text-align: left"
        label-width="100px"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'transfer_course_id',
              label: '选择课程',
              required: true,
              type: 'select',
              data: {
                url: '/xapi/course.course/myList',
                labelOptions: { value: 'id', label: 'course_name' },
                params: { course_id, is_plan: 0, is_up: 1 },
              },
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'transfer_reason',
              label: '转课理由',
              required: true,
              type: 'textarea',
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'contact_mobile',
              label: '联系方式',
              required: true,
            },
            hidden: isTeach === true,
          },
        ]"
      >
      </form-create>
      <div slot="footer" style="display: flex; justify-content: space-between">
        <el-button @click="$refs.zkDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="handleCommand('zkSave')"
          >确 定</el-button
        >
      </div>
    </diy-dialog>
  </div>
</template>

<script>
import DiyDialog from "@/components/com/diyDialog";
import StudentSave from "@/views/api/teaching/course/studentSave";
export default {
  components: { StudentSave, DiyDialog },
  props: ["course_id"],
  data() {
    return {
      where: {},
      courseInfo: {},
      isTeach: false,
      isStudent: false,
      currentData: {},
      isOpenWhite: false,
    };
  },
  methods: {
    routeUpdate() {
      this.getDetail();
    },
    getDetail() {
      this.loading = true;
      this.$http
        // .post("/xapi/course.course/detail", { id: this.course_id })
        .post("/xapi/course.course/detail_simplen", { id: this.course_id })
        .then(({ data }) => {
          this.courseInfo = data;
          console.log(this.courseInfo, "this.courseInfo");
          if (this.courseInfo && this.courseInfo.lecturers) {
            this.isOpenWhite = this.courseInfo.lecturers.data
              .map((item) => item.id)
              .includes(Number(localStorage.getItem("user_id")));
            console.log(this.isOpenWhite, "this.isOpenWhite");
          }
          this.isTeach = this.courseInfo.isTeach;
          this.isStudent = this.courseInfo.isStudent;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCommandZyueSave() {
      return this.$refs.txForm
        ? this.$refs.txForm.validate((form, valid) => {
            if (valid) {
              return this.$http
                .post("/xapi/course.course/studentZyue", {
                  course_id: this.course_id,
                  ...form,
                  user_id: this.currentData.id,
                })
                .then(({ msg }) => {
                  this.$handleMessage(msg || "操作成功！", "success", () => {
                    this.$refs.ZYueDialog.visibled(false);
                  });
                });
            }
          })
        : null;
    },
    handleCommandTxSave() {
      return this.$refs.txForm
        ? this.$refs.txForm.validate((form, valid) => {
            if (valid) {
              console.log(form,"form")
              return this.$http
                .post("/xapi/course.course/studentTx", {
                  course_id: this.course_id,
                  refund_price: this.$refs.txForm.form.refund_price,
                  price: this.currentData.price,
                  ...form,
                  user_id: this.currentData.id,
                })
                .then(({ msg }) => {
                  this.$handleMessage(msg || "退学成功！", "success", () => {
                    this.$refs.table.reload();
                    this.$refs.txDialog.visibled(false);
                  });
                });
            }
          })
        : null;
    },
    handleCommandZkSave() {
      return this.$refs.zkForm
        ? this.$refs.zkForm.validate((form, valid) => {
            if (valid) {
              return this.$http
                .post("/xapi/course.course/studentZk", {
                  course_id: this.course_id,
                  ...form,
                  user_id: this.currentData.id,
                })
                .then(({ msg }) => {
                  this.$handleMessage(msg || "转课成功！", "success", () => {
                    this.$refs.table.reload();
                    this.$refs.zkDialog.visibled(false);
                  });
                });
            }
          })
        : null;
    },
  },
};
</script>

<style scoped lang="less">
::v-deep{
  .el-table .el-table__cell{
    padding: 2px 0 !important;
  }
}
</style>